<template>
  <PageTemplate>
		<Section>
			<Card size="large">
				<CardSection>
					<Stack direction="column" space="3">
						<Heading size="1" align="center">Oil Massage: All the Info</Heading>
						<Heading size="4" color="tertiary" uppercase>Jan 14, 2022</Heading>
						<Images width="100%" height="300px" image="theme-sd/post1.jpg" />
					</Stack>
				</CardSection>
				<CardSection>
					<Grid>
						<GridContainer size="1"></GridContainer>
						<GridContainer size="10">
							<Quote>For the happiest life, days should be rigorously planned, nights left open to be discovered</Quote>
							<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse non sem dolor. Pellentesque vel tincidunt purus. Maecenas ultricies, urna dictum finibus luctus, tortor ligula blandit libero, ut accumsan nibh tellus et lacus. In hac habitasse platea dictumst. Praesent convallis sapien purus, nec consectetur est mollis ac.</Paragraph>
							<Heading size="3">Demo post title</Heading>
							<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse non sem dolor. Pellentesque vel tincidunt purus. Maecenas ultricies, urna dictum finibus luctus, tortor ligula blandit libero, ut accumsan nibh tellus et lacus. In hac habitasse platea dictumst. Praesent convallis sapien purus, nec consectetur est mollis ac.</Paragraph>
							<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse non sem dolor. Pellentesque vel tincidunt purus. Maecenas ultricies, urna dictum finibus luctus, tortor ligula blandit libero, ut accumsan nibh tellus et lacus. In hac habitasse platea dictumst. Praesent convallis sapien purus, nec consectetur est mollis ac.</Paragraph>
							<Quote align="left">Do not mind anything that anyone tells you about anyone else</Quote>
							<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse non sem dolor. Pellentesque vel tincidunt purus. Maecenas ultricies, urna dictum finibus luctus, tortor ligula blandit libero, ut accumsan nibh tellus et lacus. In hac habitasse platea dictumst. Praesent convallis sapien purus, nec consectetur est mollis ac. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse non sem dolor. Pellentesque vel tincidunt purus. Maecenas ultricies, urna dictum finibus luctus, tortor ligula blandit libero, ut accumsan nibh tellus et lacus. In hac habitasse platea dictumst. Praesent convallis sapien purus, nec consectetur est mollis ac.</Paragraph>
							<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse non sem dolor. Pellentesque vel tincidunt purus. Maecenas ultricies, urna dictum finibus luctus, tortor ligula blandit libero, ut accumsan nibh tellus et lacus. In hac habitasse platea dictumst. Praesent convallis sapien purus, nec consectetur est mollis ac.</Paragraph>
						</GridContainer>
						<GridContainer size="1"></GridContainer>
					</Grid>
				</CardSection>
				<CardSection>
					<Stack direction="column row@sm" justify="spaceBetween" align="left" space="2">
						<Link url="/sedona/blog/post" display="inline">
							<Stack direction="column" space="3" align="left">
								<Paragraph micro>PREVIOUS</Paragraph>
								<Heading size="5" color="primary">Sedona's Holiday Specials</Heading>
							</Stack>
						</Link>
						<Link url="/sedona/blog/post" display="inline">
							<Stack direction="column" space="3" align="left right@sm">
								<Paragraph micro>NEXT</Paragraph>
								<Heading size="5" color="primary">All About Cupping</Heading>
							</Stack>
						</Link>
					</Stack>
				</CardSection>
			</Card>
		</Section>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/projects/sedona/template/Page'; 
export default {
  components: {
		PageTemplate
  },
	props: {
		theme: String
	},
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  },
}

</script>

<style lang="scss">
</style>
